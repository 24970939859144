import React, {Fragment, useState, useEffect} from 'react';
import flatstore from 'flatstore';
import {isNil} from 'lodash';
import { useTranslation } from 'react-i18next';
import { currentLang, farmshopProductURL, dynamic_delivery_promise } from '../../utils/functions';
import {getFarmshopProductFull, getFarmshopProductList, getWooCommerceCart} from '../../utils/services';
import Image from '../../components/common/Image';
import {Link, useLocation} from 'react-router-dom';
import Traceability from '../../components/product/packages/Traceability';
import Price from '../../components/product/common/Price'
import LoadModules from '../../components/modules/LoadModules';
import parse from 'html-react-parser';
import FarmshopCartButton from './components/button';
import Crosssell from './components/crosssell';
import W from '../../components/common/Word';
import Meta from '../../components/common/Meta';
import NotFound from '../static/404';
import FarmshopDetailLoader from '../../assets/contentLoader/FarmshopDetailLoader'

const FarmshopDetail = ({slug, farmshop_product_info, farmshop_data, woocommerce_cart, err_data}) => {

    const [activeTab, setActiveTab] = useState(null); //useState({'traceability':false, 'farmer':false,'breed':false,'description':false});
    const [data, setData] = useState(null);
    const [unitType, setUnitType] = useState('g');
    const [list, setList] = useState(null);
    const [cart, setCart] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [totalItems, setTotalItems] = useState(null);
    const [bundleId, setBundleId] = useState(null);
    const [traceabilityData, setTraceabilityData] = useState(null);
    const [modules, setModules] = useState(null);
    const [moduleData, setModuleData] = useState(null);
    const [submissionNotice, setSubmissionNotice] = useState({ message: '', className: '' });
    const [pageLoad, setPageLoad] = useState(false);
    const {pathname} = useLocation();
    const {t} = useTranslation();

    //function gtag(){dataLayer.push(arguments);}

    const ToggleClass = (section) => {
        if(activeTab == section)
            setActiveTab(null);
        else
            setActiveTab(section);
    };

    useEffect(() => {
        farmshop_product_info = null;
    }, [pathname]);

    useEffect(() => {
        if (isNil(farmshop_data)) {
            getFarmshopProductList();
        }
        if(farmshop_data && isNil(list)){
            setList(farmshop_data);
        }
    }, [farmshop_data]);

    useEffect(() => {
    if (isNil(farmshop_product_info)) {
        getFarmshopProductFull(slug);
    }else{
        setData(farmshop_product_info);
        setBundleId(farmshop_product_info.bundle_id);
        setTraceabilityData(
            {
                "page_slug": farmshop_product_info.slug,
                "shop_brand_slug": farmshop_product_info.animal_type,
                "shop_birth_country":farmshop_product_info.birth_country,
                "shop_animal_nr":farmshop_product_info.animal_nr,
                "breed_title":farmshop_product_info.breed_title,
                "shop_fattening_country":farmshop_product_info.fattening_country,
                "farmer_title":farmshop_product_info.farmer_title,
                "shop_slaughter_place":farmshop_product_info.slaughter_place,
                "shop_portioning_place":farmshop_product_info.portioning_place,
            }
        );
        if(farmshop_product_info.modules){
            setModules(farmshop_product_info.modules);
            var mdata = {
                "farmer_image_url":farmshop_product_info.farmer_image_url,
                "farmer_header_image_url":farmshop_product_info.farmer_header_image_url,
                "farmer_title":farmshop_product_info.farmer_title,
                "farmer_location":farmshop_product_info.farmer_location,
                "farmer_slug":farmshop_product_info.farmer_slug,
                "farmer_is_new":farmshop_product_info.farmer_is_new,
                "shop_brand_slug":farmshop_product_info.animal_type,
                "animal":farmshop_product_info.shop_brand_slug
            };
            setModuleData(mdata);
        }

        if( farmshop_product_info.unit_type ){
            setUnitType(farmshop_product_info.unit_type);
        }
    }
    }, [farmshop_product_info, pathname]);

    useEffect(() => {
    if (isNil(cart)) {
        getWooCommerceCart();
    }

    if(woocommerce_cart && !isNil(woocommerce_cart)){
        var cartItems = [];
        woocommerce_cart.items.map((item)=>{
            if(!isNil(item.extensions.bundles.bundled_item_data)){
                cartItems.push(
                    {
                        "bundle_id": item.extensions.bundles.bundled_item_data.bundled_item_id,
                        "quantity": item.quantity
                    }
                );
            }
            if(!isNil(item.extensions.bundles.bundle_data)){
            }
        })
        setTotalItems(woocommerce_cart.items.length);
        setCart(cartItems);
    }
    }, [woocommerce_cart, data]);

    useEffect(() => {
    if(data){
        let dynamic_delivery_promise_text = t(dynamic_delivery_promise(t('farmshop.deadline_hour')));
        if(data.usp_list){
            data.usp_list = data.usp_list.replaceAll('{dynamic_delivery_promise}', dynamic_delivery_promise_text );
            data.usp_list = data.usp_list.replaceAll('__dynamic_delivery_promise__', dynamic_delivery_promise_text );
        }

        // default quantity to zero
        setQuantity(0);
        // update quantity if exist in cart
        if(!isNil(cart)){
            cart.forEach((x) => {
                if(x.bundle_id == data.bundled_item_id && x.quantity){
                    setQuantity(x.quantity);
                }
            });
        }
        if(!pageLoad){
            var dataLayerProductData = {
                'product_id':data.relation_slug,
                'title':data.title,
                'shop_package_price':data.price,
                'category_slug': 'grutto_custom_organic',
                'shop_animal_brand': 'Farmshop'
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'productInfo': dataLayerProductData,
                'event':'grutto-product-view'
            });
            setPageLoad(true)
        }

    }

    }, [data, cart]);

    // hide submissionNotice after certain time
    useEffect(() => {
        let timeout;
        if (submissionNotice) {
            timeout = setTimeout(() => {
                setSubmissionNotice({ message: '', className: '' });
            }, 3000);
        }
        return () => clearTimeout(timeout);
    }, [submissionNotice, setSubmissionNotice]);

    const addToCart = (product, quantity, action) => {
        flatstore.set('basket_status', true);
        let notificationText = '';
        let basketTotal = 0;

        if (window['ajax_delay']) {
            window['abortController']?.abort();
        }
        window['abortController'] = new AbortController(); // Instantiate new AbortController for each request

        const url = `/${currentLang()}/shop/ajaxflow/add-bundle-to-cart`;
        const postdata = new FormData();
        const bundleItems = {};

        postdata.append('product_id', bundleId); // Assuming bundleId is defined somewhere

        list.forEach(item => {
            let formQuantity = 0;
            cart.forEach(cartItem => {
            if (cartItem.bundle_id === item.bundled_item_id) {
                formQuantity = cartItem.quantity;
                basketTotal += formQuantity;
            }
            });

            if (item.bundled_item_id === product) {
                notificationText = `${item.title}${t(`farmshop.notification.${action === 'add' ? 'add_to_cart' : 'remove_from_cart'}`)}`;
                formQuantity = quantity;
            }

            if (formQuantity) {
                bundleItems[item.bundled_item_id] = {
                    product_id: item.meatcut_product_id, // Assuming meatcut_product_id is the product_id
                    quantity: formQuantity
                };
            }
        });

        postdata.append('bundle_items', JSON.stringify(bundleItems));

        if (basketTotal > 0) {
            flatstore.set('basket_count', { count: basketTotal });
        }

        try {
            clearTimeout(window["ajax_delay"]);
            window["ajax_delay"] = setTimeout(() => {
            const options = {
                method: 'POST',
                body: postdata,
                signal: window['abortController'].signal
            };
            fetch(url, options)
                .then(response => response.json())
                .then(response => {
                      if (response.success) {
                        if (notificationText) {
                          setSubmissionNotice({ message: notificationText, className: 'success' });
                        }
                      } else {
                        if( !isNil( response.data.message ) ){
                          setSubmissionNotice({ message: response.data.message.join('<br />'), className: 'error' });
                        }
                      }

                      var cartItems = [];
                      if( !isNil(response.data.items) ){
                        response.data.items.map((item) => {
                          if (!isNil(item.bundled_item_id)) {
                            cartItems.push({
                              bundle_id: item.bundled_item_id,
                              quantity: item.quantity,
                            });
                          }
                        });
                      }
                      setCart(cartItems);

                      flatstore.set('basket_count', response.data.cart_data);
                      flatstore.set('basket_status', false);
                })
                .catch(err => {
                console.error(err);
                if (err.name !== "AbortError") {
                    flatstore.set("basket_status", false);
                }
                });
            }, 300);
        } catch (err) {
            console.error(err);
            if (err.name !== "AbortError") {
            flatstore.set("basket_status", false);
            }
        }
    };

    if( !data && err_data && err_data == '404' ){
        return (
            <NotFound />
        );
    }

    if ((!cart || !data ) && ! err_data) {
        return (
            <div className="container mt-5 mb-5">
                <div className="row">
                <div className="col-12">
                    <FarmshopDetailLoader />
                </div>
                </div>
            </div>
        );
    }

	return (
        <Fragment>
            {data &&
                <Meta
                title={data.seo_title ?? data.title}
                description={data.seo_description ?? data.body}
                    type="farmshop"
                    type_data={data}
                    image={data.image_url}
                />
            }
            {moduleData &&
                <div>
                <LoadModules modules={modules} product={data} moduleKey={slug} moduleData={moduleData} type="top" />
                </div>
            }

            {data && cart &&

		<div className={"farmshop__full" + " type--" + data.animal_type}>
            {submissionNotice.message && (
                <div className={`response ${submissionNotice.className}`} dangerouslySetInnerHTML={{ __html: submissionNotice.message }} />
            )}
			<div className="container back-to-list">
				<div className="row">
					<div className="col">
						<Link to={farmshopProductURL('')} title={t("farmshop.list_title")}> <i className="icon-arrow-left"></i> <W>farmshop.list_title</W></Link>
					</div>
				</div>
			</div>
            <input type="hidden" id="product_data" data-title={data.title} data-price={data.price} data-slug={data.relation_slug} />
			<div className='container pb-4'>
				<div className='row'>
					<div className='col-12 col-lg-6'>
                        {data.stock_status != 'instock' && data.crosssell_items && cart &&
                            <Crosssell data={data}/>
                        }

						<div className={"img-holder "+(data.is_organic == 1 ? ' bio' : '')}>
							<Image type="farmshop.full" src={data.image_url} alt={data.title} title={data.title} className='img-fluid' />
						</div>


					</div>
					<div className='col-12 col-lg-5 offset-lg-1'>

                        <div className="farmshop__title">
                            <h1 className="heading heading--h2 original">{data.is_organic == 1 ? ' Bio' : ''} {data.title}</h1>
                            {data.animal_type == "cow" && currentLang() == "nl" &&
                                <div className="color--artichoke-dark mb-1 bio-text bio-text-below"> Grasgevoerd </div>
                            }
                            <div className="info">
                                {data.weight} <W>farmshop.weight_txt.{unitType}</W> /  <Price value={data.price_per_kilo} withCurrency={true} /> <W>farmshop.weight_txt2.{unitType}</W>
                            </div>
                        </div>

						<div className="farmshop__order">
						<div className="farmshop__order-holder">
							<div className="farmshop__price">
                            {data.price != data.regular_price &&
                                <Fragment>
                                <div className="sale_price p-prev">
                                    <Price value={data.price} withCurrency={false} />
                                </div>
                                <div className="sale_price price"><Price value={data.regular_price} withCurrency={false} /></div>
                                </Fragment>
                            }
                            {data.price == data.regular_price &&
                                <Fragment>
                                    <div className="price">
                                        <Price value={data.price} />
                                    </div>
                                </Fragment>
                            }
								<div className="info"> - {data.pieces_per_vac} x {data.weight} <W>farmshop.unit_txt.{unitType}</W></div>
							</div>
							<div className="package__btn">
                                {!isNil(quantity) && data.stock_status == 'instock' &&
                                    <FarmshopCartButton
                                    bundle_id={data.bundled_item_id}
                                    quantity={quantity}
                                    addToCartCallback={addToCart}
                                    slug={data.relation_slug}
                                    stock={data.stock}
                                    minStock={data.min_stock}
                                    title={data.title}
                                    price={data.price}
                                  />
                                }
                                {data.stock_status != 'instock' &&
                                    <div className="out-of-stock__btn"><W>farmshop.stock.out_of_stock_button</W></div>
                                }
							</div>
						</div>
                        </div>

                        {data.usp_list &&
                            <ul className='farmshop__usp'>
                                {parse(data.usp_list)}
                            </ul>
                        }

						<div className='meatcut_description'>
							{data.summary}
						</div>

                        <div className="accordion popup_accordion" id="popup_accordion">
                            <div className={"accordion__item "+(activeTab == 'traceability' ? "open" : "")}>
                                <div className="accordion__title" onClick={()=>ToggleClass('traceability')}>
                                    <h2 className="handle mb-0">
                                        <W>farmshop.traceability</W>
                                    </h2>
                                </div>
                                <div className="accordion__body">
                                    <div className="bundle__popup--traceability pt-1">
                                        <div className="desc text-justify">
                                            {(t('farmshop.traceability_txt.'+data.animal_type)) === ""
                                                ? <W>farmshop.traceability_txt.default</W>
                                                : parse(t('farmshop.traceability_txt.'+data.animal_type))
                                            }
                                        </div>
                                        <div className='vertical-traceability'>
                                            <Traceability type="farmshop" data={traceabilityData} header={false} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`accordion__item  ${activeTab == 'farmer' ? "open" : ""}`}>
                                <div className="accordion__title" onClick={()=>ToggleClass('farmer')}>
                                    <h2 className="handle mb-0">
                                        <span> <span className='farmer_type'>{t('farmers.'+data.animal_type)} </span><span className="farmer_name"> {data.farmer_title}</span> </span>
                                    </h2>
                                </div>
                                <div className="accordion__body">
                                    <div className="bundle__popup--farmer">
                                        <div className="body">
                                            {data.farmer_summary}
                                        </div>
                                        <div className="img" id="modal_farmer_image"><Image
                                                type="farmers.avatar"
                                                src={data.farmer_image_url}
                                                alt={data.farmer_title}
                                                title={data.farmer_title} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`accordion__item  ${activeTab == 'breed' ? "open" : ""}`}>
                                <div className="accordion__title" onClick={()=>ToggleClass('breed')}>
                                    <h2 className="handle mb-0">
                                        <span> <span className='breed_pre_title'><W>farmshop.breed_prev_txt</W></span> <span className="breed_title"> {data.breed_title}</span> </span>
                                    </h2>
                                </div>
                                <div className="accordion__body">
                                    <div className="bundle__popup--breed">
                                        <div className="body">
                                            <div id="modal_breed_content">{data.breed_summary}</div>
                                        </div>
                                        <div className="img" id="modal_breed_image"><Image
                                                type="breeds.avatar"
                                                src={data.breed_image_url}
                                                alt={data.breed_title}
                                                title={data.breed_title} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`accordion__item  ${activeTab == 'description' ? "open" : ""}`}>
                                <div className="accordion__title" onClick={()=>ToggleClass('description')}>
                                    <h2 className="handle mb-0">
                                        <W>farmshop.additional_info</W>
                                    </h2>
                                </div>
                                <div className="accordion__body">
                                    <div className="bundle__popup--definition pt-1">
                                        {/* <h3 className="bundle__popup--title"><W>farmshop.additional_info</W></h3> */}
                                        {data.body && parse(data.body)}
                                    </div>
                                </div>
                            </div>
                        </div>

					</div>
				</div>
			</div>
		</div>
        }
        {moduleData &&
            <div>
            <LoadModules modules={modules} product={data} moduleData={moduleData} type="bottom" />
            </div>
        }
        </Fragment>

	)
}

export default flatstore.connect(['farmshop_product_info', 'woocommerce_cart', 'farmshop_data', 'err_data'])(FarmshopDetail)
