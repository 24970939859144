import React, {useState, useEffect, Fragment, useRef} from 'react'
import flatstore from 'flatstore';
import Image from '../../../common/Image'
import Swiper from 'react-id-swiper';
import parse from 'html-react-parser';
import 'swiper/scss'

import {isNil, isArray} from 'lodash';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AnimalFill from '../../common/AnimalFill';
import AnimalTag from '../../common/AnimalTag';

const ProductSlider = ({data, product_meatoptions_data, type, url, percentageBoxData}) => { // Added percentageBoxData prop
    const [swiper, setSwiper] = useState(null);
    const [count, setCount] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const { t } = useTranslation();
    const {pathname} = useLocation();
    const ref = useRef(null);
    const [slides, setSlides] = useState(null);
    var device = (window.innerWidth >= 992) ? 'desktop' : (window.innerWidth > 768) ? 'tablet' : 'mobile';

    useEffect(() => {
        if (swiper !== null) {

        }

    }, [pathname])

    useEffect(() => {
        var datacount = (data && isArray(data)) ? data.length : 0;
        setCount(datacount);
        if(!isNil(swiper)){
            swiper.imagesLoaded = datacount;
            if(swiper.hasOwnProperty('slideTo')){
                swiper.slideTo(0);
                setActiveSlide(0);
                swiper.update();
            }
        }

    }, [data, pathname])

    useEffect(() => {
        if (ref.current !== null && ref.current.swiper !== null) {
            setSwiper(ref.current.swiper);
        }
    }, [ref, slides])

    let touchThreshold = 30;
    if(window.innerWidth <= 768 ){
        touchThreshold = 0;
    }

    if(data && isNil(has_video)){
        var has_video = false;
        data.map(item=>{if(item.type == 'video_url'){ has_video = true; }});
    }
    //console.log(has_video);
    const next = () => {
        if(activeSlide !== count-1){
            setActiveSlide(activeSlide+1);
            if (swiper !== null) {
                swiper.slideNext();
            }
        }

    };

    const prev = () => {
        if(activeSlide !== 0){
            setActiveSlide(activeSlide-1);
            if (swiper !== null) {
                swiper.slidePrev();
            }
        }
    };

    const slideTo = index => {
        if(!isNil(swiper)){
            swiper.slideTo(index);
            setActiveSlide(index);
        }
    };

    useEffect(() => {
        if (swiper !== null) {
            swiper.on("slideChange", () => {

                if(activeSlide !== count-1){
                    setActiveSlide(swiper.realIndex);
                    //swiper.touchMoveStopPropagation = true;
                }else{
                    //swiper.touchMoveStopPropagation = false;
                }
            });
        }

        return () => {
            if (swiper !== null) {
                swiper.off("slideChange", () => setActiveSlide(swiper.realIndex));
            }
        };
    }, [swiper]);

    useEffect(() => {
        var items = [];

        if(product_meatoptions_data){
            data.map((item, index)=>{
                if(!isNil(product_meatoptions_data[item.shop_name])){
                    item.total_pieces = product_meatoptions_data[item.shop_name] * item.pieces_per_vac;
                }
                items.push(item);
            })
        }else{
            items = [...data];
        }

        // Add the percentage box as the first slide if percentageBoxData is provided
        if (percentageBoxData) {
            items.unshift({
                type: 'percentage_box', // New item type for percentage box
                content: percentageBoxData
            });
        }

        setSlides(items);
        if(swiper)
            swiper.update();
    }, [product_meatoptions_data, data, percentageBoxData])

    return (
        <section className={"carousel__holder "}>

        <div className={"carousel"}>
            <ul className="carousel__nav">
                {slides && slides.length > 1 && count && slides.map((item, index)=>{
                    return <li key={index} className={index === activeSlide ? "active" : ""} onClick={()=>slideTo(index)}></li>
                })}
            </ul>
            <div className={"items"}>
                <Swiper threshold={touchThreshold} ref={ref} noSwiping={(type == 'products.card' && device == 'mobile') ? true : false}>
                    {slides && slides.map((item, index) => {
                        // Check for the percentage box type and render it as the first slide
                        if(item.type === 'percentage_box' && item.content){
                            return (
                                <div id={"sliderItem-percentageBox"} key={index} className={"item percentage-box-slide"}>
                                    <div className="percentage__box-holder">
                                        <div className="percentage__box">
                                            <Link to={url}>
                                                <AnimalFill
                                                    percentage={item.content.shop_product_percentage_sold}
                                                    animal={item.content.shop_brand_slug}
                                                    category_slug={item.content.shop_category_slug}
                                                />
                                                {item.content.category_slug !== 'grutto_farmer_package' && (
                                                    <AnimalTag
                                                        country={item.content.shop_animal_nr_prefix}
                                                        amount={item.content.shop_animal_nr}
                                                        currentAnimal={item.content.shop_brand_slug}
                                                    />
                                                )}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }

                        // Render the regular items
                        if(item.image_url){
                            item.content = item.image_url;
                            item.type = 'image';
                        }
                        var unit_single_plural = t('product.'+item.unit_single+'_interval', {postProcess: 'interval', count: item.total_pieces})
                        var gram_plural = t('product.grameach_interval', {postProcess: 'interval', count: item.total_pieces})

                        return (
                            <div id={"sliderItem"+item.slideritem_id} key={index} className={"item"+(!isNil(item.background_color) ? " "+item.background_color : "")+(!isNil(item.custom_class) ? " "+item.custom_class : "")}>
                                {item.type == 'image' &&
                                    <Fragment>
                                        {item.content &&
                                            <Fragment>
                                                {(url && device == 'mobile' && type == 'products.card') &&
                                                    <Link to={url}>
                                                        <Image type={type} alt={item.image_alt_text ? item.image_alt_text : ''} className="slider-img" src={item.content} />
                                                    </Link>
                                                }
                                                {(type != 'products.card' || (device != 'mobile' && type == 'products.card')) &&
                                                    <Image type={type} alt={item.image_alt_text ? item.image_alt_text : ''} title={item.image_alt_text ? item.image_alt_text : ''} className="slider-img" src={item.content} />
                                                }
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                {item.shop_title &&
                                <div className="carousel__content">
                                    <Fragment>
                                        {t('product.product_details_slider_item', {title: item.shop_title, total_pieces: item.total_pieces, unit: unit_single_plural, weight: item.weight, gram: gram_plural})}
                                    </Fragment>
                                </div>
                                }
                            </div>
                        )
                    })}
                </Swiper>
            </div>
            {slides && slides.length > 1 && count &&
            <div className="carousel__bottom">
                <a className={"prev"+(activeSlide > 0 ? " active" : "")} onClick={prev}> <span className="icon-arrow-right"></span> </a>
                <a className={"next"+(activeSlide < count-1 ? " active" : "")} onClick={next}> <span className="icon-arrow-right"></span> </a>
            </div>
            }
        </div>
        </section>
    )
}

export default flatstore.connect(['product_meatoptions_data'])(ProductSlider);
